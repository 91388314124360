<template>
  <div class="filterDialog">
    <div class="main_right">
      <!--  -->
      <div class="right_one">
        <p class="r_first">学段选择</p>
        <p class="r_second">
          <span
            v-for="item in gradedata"
            :key="item.id"
            @click="nextdata(item.id,1)"
            :class="subjectForm.gradeId===item.id?'bgcs':'bgc'"
          >{{item.name}}</span>
        </p>
      </div>
      <!--  -->
      <div class="right_two">
        <p class="r_first">年级选择</p>
        <p class="r_second">
          <span
            v-for="item in classdata"
            :key="item.id"
            @click="nextdata(item.id,2)"
            :class="subjectForm.classId===item.id?'bgcs':'bgc'"
          >{{item.name}}</span>
        </p>
      </div>
      <!--  -->
      <div class="right_three">
        <p class="r_first">学科类型</p>
        <p class="r_second">
          <span
            v-for="item in typedata"
            :key="item.id"
            @click="nextdata(item.id,3)"
            :class="subjectForm.typeId===item.id?'bgcs':'bgc'"
          >{{item.name}}</span>
        </p>
      </div>
      <!--  -->
      <div class="right_four">
        <p class="r_first">学科选择</p>
        <p class="r_second">
          <span
            v-for="item in subjectData"
            :key="item.id"
            @click="nextdata(item.id,4)"
            :class="subjectForm.subjectId===item.id?'bgcs':'bgc'"
          >{{item.name}}</span>
        </p>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureDialog" size="mini">确 定</el-button>
      <el-button @click="cancleDialog" size="mini">取 消</el-button>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      gradedata: null,
      classdata: null,
      typedata: null,
      subjectData: null,
      //课程表单
      subjectForm: {
        gradeId: 0,
        classId: 0,
        typeId: 0,
        subjectId: 0,
      },
    };
  },
  created() {
    this.queryPeriod();
    this.queryGrade();
    this.querySubjectType();
    this.querySubject();
  },
  methods: {
    //----数据切换
    nextdata(id, num) {
      if (num === 1) {
        this.queryGrade(id);
        this.subjectForm.gradeId = id;
        this.subjectForm.classId = 0;
        // this.queryCourselist();
      } else if (num === 2) {
        this.subjectForm.classId = id;
        // this.queryCourselist();
      } else if (num === 3) {
        this.querySubject(id);
        this.subjectForm.typeId = id;
        this.subjectForm.subjectId = 0;
        // this.queryCourselist();
      } else if (num === 4) {
        this.subjectForm.subjectId = id;
        // this.queryCourselist();
      }
      // this.queryCourselist();
    },
    //获取学段信息
    async queryPeriod() {
      const { data, status } = await this.$Http.queryPeriod();
      // console.log(data, status, "获取学段信息");
      if (status === 200) {
        let all = {
          id: 0,
          name: "全部",
        };
        let allData = [all, ...data];
        this.gradedata = allData;
      }
    },
    //获取年级信息
    async queryGrade(id) {
      let ids = [];
      if (id == 0 || id == undefined) {
        id = "";
      } else {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      // console.log(data, status, "获取年级信息");
      if (status === 200) {
        let all = {
          id: 0,
          name: "全部",
        };
        let allData = [all, ...data];
        this.classdata = allData;
      }
    },
    //获取学科类型
    async querySubjectType() {
      const { data, status } = await this.$Http.querySubjectType();
      // console.log(data, status, "获取学科类型信息");
      if (status === 200) {
        let all = {
          id: 0,
          name: "全部",
        };
        let allData = [all, ...data];
        this.typedata = allData;
      }
    },
    //获取学科信息
    async querySubject(id) {
      let ids = [];
      if (id == 0 || id == undefined) {
        id = "";
      } else {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      // console.log(data, status, "获取学科信息");
      if (status === 200) {
        let all = {
          id: 0,
          name: "全部",
        };
        let allData = [all, ...data];
        this.subjectData = allData;
      }
    },

    //取消
    cancleDialog() {
      this.$emit("shutDialog", false);
      // this.dealdata();
    },
    // 确定
    sureDialog() {
      this.$emit("filtlerData", this.subjectForm);
      this.$emit("shutDialog", false);
      // this.dealdata();
    },
    // 重置
    dealdata() {
      this.subjectForm.gradeId = 0;
      this.subjectForm.classId = 0;
      this.subjectForm.typeId = 0;
      this.subjectForm.subjectId = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.filterDialog {
  .main_right {
    padding: 20px;
    .right_one,
    .right_two,
    .right_three,
    .right_four {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      .r_first {
        width: 120px;
        // font-size: 18px;
        // font-weight: 600;
      }

      .r_second {
        width: 890px;
        margin-left: 10px;
        .bgc {
          color: #a6a6a6;
        }
        .bgcs {
          color: #1977f9;
        }
        span {
          cursor: pointer;
          // color: #a6a6a6;
          margin-left: 15px;
        }
        span:hover {
          color: #1977f9;
        }
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>