<template>
  <div class="rightslog">
    <!--  上传系列课程 -->
    <div class="rightslog_center">
      <!-- <el-steps :active="1" simple>
        <el-step title="基本信息" icon="el-icon-edit"></el-step>
        <el-step title="增减课程" icon="el-icon-upload"></el-step>
      </el-steps>-->

      <!--  -->
      <!--  -->
      <el-tabs v-model="activeName" class="card">
        <el-tab-pane label="基本信息" name="first" :disabled="true">
          <el-form ref="basicRef" :rules="upRules" :model="basicForm" label-width="150px">
            <el-form-item label="系列课程标题" prop="title">
              <el-input v-model="basicForm.title"></el-input>
            </el-form-item>
            <el-form-item label="课程简介">
              <el-input type="textarea" v-model="basicForm.introduction"></el-input>
            </el-form-item>
            <el-form-item label="系列课程标签">
              <el-radio-group v-model="basicForm.labelId">
                <el-radio
                  v-for="item in serisedata"
                  :key="item.id"
                  :label="item.id"
                  @click.native.prevent="clickitem(item.id)"
                >{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <classcover @pirctureUrl="editpircture" :previewImg="previewImg"></classcover>
            <el-form-item>
              <el-button type="primary" @click="goback" size="mini">返回</el-button>
              <el-button type="primary" @click="nextstep" size="mini">下一步</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="增减课程" name="second" class="r_tab" :disabled="true">
          <div class="up_form">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>课程库</span>
              </div>
              <div class="clear_text">
                <div class="t_search">
                  <el-button type="primary" size="mini" @click="gotolist">移入</el-button>
                  <el-input v-model="l_inputName" size="mini" suffix-icon="el-icon-search" @change="queryCourselist"></el-input>
                  <el-button type="primary" size="mini" @click="openFilter">条件筛选</el-button>
                </div>
                <el-table :data="lTab" style="width: 100%" @selection-change="handleSelectionChange" height="528">
                  <el-table-column type="selection" width="50" align="center"></el-table-column>
                  <!-- :index="computeTableIndex" -->
                  <el-table-column type="index" width="50" align="center" label="序号"></el-table-column>
                  <el-table-column prop="title" label="课程名称" width="120" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="gradeName" label="年级" width="100" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="subjectName" label="学科" width="100" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="createName" label="上传者" width="100" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="createTime" label="上传时间" width="180" show-overflow-tooltip></el-table-column>
                </el-table>
                <el-pagination
                  style="display:flex;justify-content: center;margin-top: 20px;"
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="curpage"
                  :page-sizes="[10, 20, 30, 40, 50]"
                  :page-size="pageSize"
                  layout=" prev, pager, next, sizes"
                  :total="total"
                ></el-pagination>
              </div>
            </el-card>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>已添加课程</span>
              </div>
              <div class="clear_text">
                <div class="t_search">
                  <el-button type="primary" size="mini" @click="outlist">移出</el-button>
                  <!-- <el-input v-model="l_inputName" size="mini" suffix-icon="el-icon-search" @change="queryCourselist"></el-input> -->
                  <!-- <el-button type="primary" size="mini" @click="openFilter">条件筛选</el-button> -->
                </div>
                <el-table
                  :data="rTabs"
                  style="width: 100%"
                  @selection-change="handleSelectionChanges"
                  row-key="id"
                  class="tuozhiatab"
                  height="528"
                >
                  <el-table-column type="selection" width="50" align="center"></el-table-column>
                  <!-- :index="computeTableIndex" -->
                  <el-table-column type="index" width="50" F label="序号"></el-table-column>
                  <!-- <el-table-column prop="id" width="50" align="center" label="id号"></el-table-column> -->
                  <el-table-column prop="title" label="课程名称" width="120" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="gradeName" label="年级" width="100" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="subjectName" label="学科" width="100" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="createName" label="上传者" width="100" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="createTime" label="上传时间" width="180" show-overflow-tooltip></el-table-column>
                  <!-- <el-table-column label="操作" align="center" width="60px">
                    <template>
                      <el-tooltip effect="light" content="点击拖动排序" placement="right">
                        <i class="el-icon-sort" style="font-size: 20px; cursor: pointer"></i>
                      </el-tooltip>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </el-card>
          </div>
          <div class="downbtns">
            <el-button type="primary" size="mini" @click="backstep">上一步</el-button>
            <el-button type="primary" size="mini" @click="saveBaseSerise">完成</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-dialog
        title="筛选条件"
        :visible.sync="filterDialog"
        width="41%"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <filterlog @filtlerData="editfiltlerData" @shutDialog="editshutDialog"></filterlog>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import classcover from "./component/classcover.vue";
import filterlog from "./component/filterlog.vue";
// import { mapState } from "vuex";
import Sortable from "sortablejs";

export default {
  name: "index",
  data() {
    return {
      // 系列课程标签
      serisedata: null,
      // tabs显示页面
      activeName: "first",
      //基本信息表单
      basicForm: {
        title: null,
        introduction: null,
        labelId: null,
        files: null, //图片文件---图片id
      },
      //基本信息规则
      upRules: {
        title: [{ required: true, message: "请输入课程标题", trigger: "blur" }],
      },
      //
      // 图片预览
      previewImg: null,
      //  筛选条件弹框
      filterDialog: false,

      //  增加减少课程
      l_inputName: null, //输入框
      lTabs: null, //表格数据
      searchForm: {
        gradeId: 0,
        classId: 0,
        typeId: 0,
        subjectId: 0,
      },
      r_inputName: null, //输入框
      rTabs: [],
      // 多选数据
      multipleSelection: null,
      multipleSelections: null,

      // 分页
      // 移入
      pageSize: 10,
      curpage: 1,
      total: null,

      //
    };
  },
  created() {
    this.querySeriseLable();
    this.queryCourselist();
    // console.log(this.$route.query.id, "this.query");
    if (this.$route.query.id !== undefined) {
      this.queryclassRaw(this.$route.query.id);
    }
  },
  mounted() {
    //这里我是在mounted中调用了拖拽方法，一般获取到表格数据后nextTick中就可以调用拖拽方法
    this.setRowSort();
  },
  methods: {
    setRowSort() {
      this.$nextTick(() => {
        this.drop = Sortable.create(
          document.querySelector(".tuozhiatab .el-table__body-wrapper tbody"),
          {
            //这里指操作的图标，点击图标可以进行拖拽，若注释掉，则点击此行都可以进行拖拽
            // handle: ".el-icon-sort",
            // onEnd(元素拖拽结束)
            onEnd: ({ newIndex, oldIndex }) => {
              console.log(this.rTabs, "rTabrTabrTabrTab");
              this.rTabs.splice(newIndex, 0, this.rTabs.splice(oldIndex, 1)[0]);
              var newArray = this.rTabs.slice(0);
              this.rTabs = [];
              this.$nextTick(function () {
                this.rTabs = newArray;
                this.rTabs = newArray;
              });
            },
          }
        );
      });
    },

    // 获取系列课程的标签
    async querySeriseLable() {
      await this.$Http.querySeriseLable().then((res) => {
        // console.log(res, "获取系列课程");
        if (res.status == 200) {
          this.serisedata = res.data;
        }
      });
    },
    //系列课程标签
    clickitem(e) {
      e === this.basicForm.labelId
        ? (this.basicForm.labelId = "")
        : (this.basicForm.labelId = e);
    },
    // 编辑课程的raw获取
    async queryclassRaw(id) {
      // console.log("编辑");
      // if (this.seriesRowId == null) {
      //   return;
      // } else {
      // let idlist = [this.seriesRowId];
      const { data, status } = await this.$Http.querySerise({
        id: id, //系列id
        // pageNo: this.currentPage, //页码
        // pageSize: 10, //每页数量
        sortId: 1, //排序ID(1-上传时间，2-点赞量，3-收藏量)
        sortType: 2, //排序类型（1-正序，2-倒序）
        // ...this.classFrom,
        // seriseName: seriseName, //系列名称
        // startTime: startTime, //开始时间
        // endTime: endTime, //结束时间
      });
      if (status == 200) {
        // console.log(data, "编辑课程");
        let aa = data.list[0];
        this.basicForm.title = aa.seriseName;
        this.basicForm.introduction = aa.content;
        this.basicForm.files = aa.picture.id;
        this.previewImg = aa.picture.url;
        for (let b = 0; b < this.serisedata.length; b++) {
          if (this.serisedata[b].name == aa.labelName) {
            this.basicForm.labelId = this.serisedata[b].id;
          }
        }
        console.log(aa.courseList);
        // this.queryCourselists(aa.courseList);
        for (let a = 0; a < aa.courseList.length; a++) {
          console.log(aa.courseList[a], "aaaa", a);
          await this.queryCourselists(aa.courseList[a]);
        }
      }
    },
    async queryCourselists(id) {
      let datas = JSON.stringify({
        courseIdList: [id], //课程id集合
        courseType: 1, //课程类型(1-课程，2-资料)
        sortId: 1, //排序ID(1-上传时间，2-播放量，3-评价数量，4-收藏数量，5-点赞数量，6-下载量)
        sortType: 2, //排序类型（1-正序，2-倒序）
      });
      const { data, status } = await this.$Http.queryCourselist(datas);
      if (status === 200) {
        // console.log(data, status, "获取课程", data.list[0]);
        let aData = data.list[0];
        // let aData = JSON.parse(JSON.stringify(data.list[0]));
        aData.periodName = aData.periodName.join("/");
        aData.gradeName = aData.gradeName.join("/");
        aData.subjectName = aData.subjectName.join("/");
        this.rTabs.push(aData);
        // window.sessionStorage.setItem("classInfo", JSON.stringify(data.list));
      }
    },
    // 多选
    handleSelectionChange(val) {
      // console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    handleSelectionChanges(val) {
      // console.log(val, "xuzhogn");
      this.multipleSelections = val;
    },
    // 移入数据
    gotolist() {
      this.rTabs.push(...this.multipleSelection);
    },
    // 移出
    outlist() {
      let set1 = new Set(this.rTabs);
      let set2 = new Set(this.multipleSelections);
      let diffrence = new Set([...set1].filter((x) => !set2.has(x)));
      // console.log("差集", diffrence);
      this.rTabs = [...diffrence];
    },
    //
    // 上传图片
    editpircture(data) {
      this.basicForm.files = data;
    },
    // 返回
    goback() {
      this.$confirm("即将退出,请确认是否保存了数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.$store.commit("userinfo/editseriesRowId", null);
          // this.$store.commit("userinfo/editshowSeries", false);
          this.$router.push({
            path: "/myseries",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 下一步
    nextstep() {
      this.$refs["basicRef"].validate((valid) => {
        if (!valid) return;
        if (this.basicForm.files == null)
          return this.$message.error("请上传封面");
        this.activeName = "second";
      });
    },
    //上一步
    backstep() {
      this.activeName = "first";
    },

    //打开弹窗
    openFilter() {
      this.filterDialog = true;
    },
    // 关闭弹框
    editshutDialog() {
      this.filterDialog = false;
    },
    // 筛选条件
    editfiltlerData(data) {
      let aaa = (arra, arrb) =>
        Object.keys(arra).forEach((key) => {
          arra[key] = arrb[key];
        });

      aaa(this.searchForm, data);
      // console.log(this.searchForm, "this.searchForm2");
      this.queryCourselist();
    },
    // 获取课程信息
    async queryCourselist() {
      let periodId = this.searchForm.gradeId; //学段ID
      let gradeId = this.searchForm.classId; //年级ID
      let serviceType = this.searchForm.typeId; //类型
      let subjectId = this.searchForm.subjectId; //学科ID
      if (periodId == 0) {
        periodId = null;
      }
      if (gradeId == 0) {
        gradeId = null;
      }
      if (serviceType == 0) {
        serviceType = null;
      }
      if (subjectId == 0) {
        subjectId = null;
      }
      let datas = JSON.stringify({
        // courseIdList: classdata.classInformation, //课程id集合
        courseName: this.l_inputName, //课程名称
        courseType: 1, //课程类型(1-课程，2-资料)
        periodId, //学段ID
        gradeId, //年级ID
        serviceType, //类型
        subjectId, //学科ID
        sortId: 1, //排序ID(1-上传时间，2-播放量，3-评价数量，4-收藏数量，5-点赞数量，6-下载量)
        sortType: 2, //排序类型（1-正序，2-倒序）
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      const { data, status } = await this.$Http.queryCourselist(datas);
      if (status == 200) {
        // console.log(data, "课程数九");
        this.lTabs = data;
        // this.total = data.total;
      }
    },
    // 保存系列课程
    async saveBaseSerise() {
      console.log(this.rTabs, "aaa", this.rTabs);
      let idLsits = [];
      for (let a = 0; a < this.rTabs.length; a++) {
        idLsits.push(this.rTabs[a].id);
      }
      let ids = null;
      if (this.$route.query.id !== undefined) {
        ids = this.$route.query.id;
      }
      console.log(idLsits, "adfasdf");
      let rdata = [...new Set(idLsits)];
      console.log(rdata, "rdata");
      const { data, status } = await this.$Http.saveBaseSerise({
        conent: this.basicForm.introduction, //系列简介
        labelId: this.basicForm.labelId, //标签id
        courseIdList: rdata, //系列下的课程id
        id: ids, //系列id 如果更新必传，若不传则进行添加
        pictureId: this.basicForm.files, //图片id
        title: this.basicForm.title, //系列名称
      });
      if (status == 200) {
        console.log(data, status, "完成系列课程");
        this.$router.push({
          path: "/myseries",
        });
        // this.$store.commit("userinfo/editseriesRowId", null);
        // this.$store.commit("userinfo/editshowSeries", false);
        // this.$emit("seridata");
      }
    },

    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryCourselist();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCourselist();
    },
  },
  computed: {
    // ...mapState({
    //   seriesRowId: (state) => state.userinfo.seriesRowId,
    // }),
    lTab() {
      // console.log(this.lTabs, "tabData2");
      let a = this.lTabs;
      if (a !== null) {
        let aa = this.lTabs.list;

        this.total = this.lTabs.total;
        if (aa[0].periodName.join === undefined) {
          return aa;
        }
        for (let x = 0; x < aa.length; x++) {
          aa[x].gradeName = aa[x].gradeName.join("/");
          aa[x].subjectName = aa[x].subjectName.join("/");
        }
        return aa;
      }
    },
    // rTabs() {
    //   console.log(this.rTabs, "tabData2111");
    //   let person = this.rTabs;
    //   let obj = {};
    //   person = person.reduce((cur, next) => {
    //     obj[next.id] ? "" : (obj[next.id] = true && cur.push(next));
    //     return cur;
    //   }, []); //设置cur默认类型为数组，并且初始值为空的数组
    //   return person;
    // },
  },
  components: {
    classcover,
    filterlog,
  },
};
</script>
<style lang="scss" scoped>
.rightslog {
  .rightslog_center {
    background: #fff;
    padding: 0 20px;
    .card {
      ::v-deep .el-tabs__item {
        font-size: 18px;
        font-weight: 600;
      }
      ::v-deep .el-tabs__active-bar {
        width: 70px !important;
      }
      ::v-deep .is-disabled {
        color: #000;
      }
      .el-input {
        width: 300px;
      }
      .el-textarea {
        ::v-deep textarea {
          width: 500px;
          height: 100px;
        }
      }
    }
    .r_tab {
      .up_form {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .box-card {
          width: 49%;
          .clearfix {
            // width: 54px;
            // height: 24px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            // line-height: 21px;
          }
          .clear_text {
            .t_search {
              padding: 0 20px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      .downbtns {
        display: flex;
        justify-content: flex-end;
      }
    }

    //
    //
    //
  }
}
</style>